<template>
  <b-form @submit.prevent="onSubmit" v-if="order" class="order-form">
    <b-card>
      <b-form-group
        v-if="type === 'update'"
        class="row"
        :label="$t('forms.orderId')"
        label-for="h-id"
        label-cols-md="2"
        content-cols-lg="4"
      >
        <b-form-input v-model="order.id" id="h-id" disabled/>
      </b-form-group>
      <b-form-group
        :class="{ row, error: v$.order.statusSelect.$errors.length }"
        :label="$t('forms.status')"
        label-for="h-status"
        label-cols-md="2"
        content-cols-lg="4"
      >
        <v-select
          id="h-status"
          v-model="order.statusSelect"
          :options="statuses"
        />
        <div
          class="input-errors"
          v-for="error of v$.order.statusSelect.$errors"
          :key="error.$uid"
        >
          <small class="text-danger">{{ error.$message }}</small>
        </div>
      </b-form-group>
      <b-form-group
        v-if="order.paymentMethod === 'BANK_TRANSFER'"
        class="row"
        :label="$t('forms.financialStatus')"
        label-for="financialStatus"
        label-cols-md="2"
        content-cols-lg="4"
      >
        <v-select
          id="h-status"
          v-model="order.financialStatusSelect"
          :options="financialStatuses"
        />
      </b-form-group>
      <b-form-group
        id="input-group-confirmation-number"
        :label="$t('forms.confirmationNumber')"
        label-for="confirmationNumber"
        class="row"
        label-cols-md="2"
        content-cols-lg="4"
      >
        <b-form-input v-model="order.confirmationNumber" id="confirmationNumber"/>
      </b-form-group>
      <fieldset>
        <h3>{{ $t("forms.product") }}</h3>
        <table class="product-table">
          <tr>
            <td class="width-20">
              <b-form-group
                id="input-group-resort"
                :label="$t('forms.resort')"
                label-for="resort-name"
                :class="{ error: v$.order.resortSelect.$errors.length }"
              >
                <v-select
                  id="resort-name"
                  v-model="order.resortSelect"
                  :options="resorts"
                />
                <div
                  class="input-errors"
                  v-for="error of v$.order.resortSelect.$errors"
                  :key="error.$uid"
                >
                  <small class="text-danger">{{ error.$message }}</small>
                </div>
              </b-form-group>
            </td>
            <td class="width-20">
              <b-form-group
                id="input-group-startDate"
                :label="$t('forms.fromDate')"
                label-for="fromDate"
                :class="{ error: v$.order.fromDate.$errors.length }"
              >
                <datepicker
                  id="fromDate"
                  v-model="order.fromDate"
                  class="form-input"
                  inputFormat="dd-MM-yyyy"
                />
                <div
                  class="input-errors"
                  v-for="error of v$.order.fromDate.$errors"
                  :key="error.$uid"
                >
                  <small class="text-danger">{{ error.$message }}</small>
                </div>
              </b-form-group>
            </td>
            <td class="width-20">
              <b-form-group
                id="input-group-endDate"
                :label="$t('forms.toDate')"
                label-for="fromDate"
                :class="{ error: v$.order.fromDate.$errors.length }"
              >
                <datepicker
                  id="toDate"
                  v-model="order.toDate"
                  class="form-input"
                  inputFormat="dd-MM-yyyy"
                />
                <div
                  class="input-errors"
                  v-for="error of v$.order.toDate.$errors"
                  :key="error.$uid"
                >
                  <small class="text-danger">{{ error.$message }}</small>
                </div>
              </b-form-group>
            </td>
            <td class="width-20">
              <b-form-group
                id="input-group-room"
                :label="$t('forms.room')"
                label-for="room-name"
                :class="{ error: v$.order.roomSelect.$errors.length }"
              >
                <v-select
                  id="room-name"
                  v-model="order.roomSelect"
                  :options="rooms"
                />
                <div
                  class="input-errors"
                  v-for="error of v$.order.roomSelect.$errors"
                  :key="error.$uid"
                >
                  <small class="text-danger">{{ error.$message }}</small>
                </div>
              </b-form-group>
            </td>
            <td class="width-20 pull-right" rowspan="2">
              <b-form-group
                id="input-group-room"
                :label="$t('forms.costCurrency').replace(/%currencyCode%/g, order.currency)"
                label-for="room-name"
                class=""
              >
                {{ order.roomPrice }}
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td class="width-20">
              <b-form-group
                id="input-group-adults"
                :label="$t('forms.adults')"
                label-for="adults"
                :class="{ error: v$.order.adults.$errors.length }"
              >
                <b-form-input
                  v-model="order.adults"
                  id="adults"
                  type="number"
                />
                <div
                  class="input-errors"
                  v-for="error of v$.order.adults.$errors"
                  :key="error.$uid"
                >
                  <small class="text-danger">{{ error.$message }}</small>
                </div>
              </b-form-group>
            </td>
            <td class="width-20">
              <b-form-group
                id="input-group-children"
                :label="$t('forms.children')"
                label-for="children"
                :class="{ error: v$.order.children.$errors.length }"
              >
                <b-form-input
                  v-model="order.children"
                  id="children"
                  type="number"
                />
                <div
                  class="input-errors"
                  v-for="error of v$.order.children.$errors"
                  :key="error.$uid"
                >
                  <small class="text-danger">{{ error.$message }}</small>
                </div>
              </b-form-group>
            </td>
            <td class="width-20">
              <b-form-group
                id="input-group-infants"
                :label="$t('forms.infants')"
                label-for="infants"
                :class="{ error: v$.order.infants.$errors.length }"
              >
                <b-form-input
                  v-model="order.infants"
                  id="infants"
                  type="number"
                />
                <div
                  class="input-errors"
                  v-for="error of v$.order.infants.$errors"
                  :key="error.$uid"
                >
                  <small class="text-danger">{{ error.$message }}</small>
                </div>
              </b-form-group>
            </td>
            <td class="width-20">
              <b-form-group
                id="input-group-mealplan"
                :label="$t('forms.mealplan')"
                label-for="room-name"
                :class="{ error: v$.order.mealPlanSelect.$errors.length }"
              >
                <v-select
                  id="room-name"
                  v-model="order.mealPlanSelect"
                  :options="mealplans"

                />
                <div
                  class="input-errors"
                  v-for="error of v$.order.mealPlanSelect.$errors"
                  :key="error.$uid"
                >
                  <small class="text-danger">{{ error.$message }}</small>
                </div>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td class="width-20">
              <b-form-group
                id="input-group-transfer"
                :label="$t('forms.transfer')"
                label-for="transfer"
                :class="{ error: v$.order.transferSelect.$errors.length }"
              >
                <v-select
                  id="room-name"
                  v-model="order.transferSelect"
                  :options="transfers"
                />
                <div
                  class="input-errors"
                  v-for="error of v$.order.transferSelect.$errors"
                  :key="error.$uid"
                >
                  <small class="text-danger">{{ error.$message }}</small>
                </div>
              </b-form-group>
            </td>
            <td colspan="4"></td>
          </tr>
          <tr>
            <td colspan="4">
              {{ $t("forms.transfer") }}
            </td>
            <td class="pull-right">
              {{ order.transferPrice }} {{ order.currency }}
            </td>
          </tr>
          <tr>
            <td colspan="4">
              {{ $t("forms.processingFee") }}
            </td>
            <td class="pull-right">
              {{ order.paymentProcessingFee }} {{ order.currency }}
            </td>
          </tr>
          <tr v-if="order.discount > 0">
            <td colspan="4">
              {{ $t("forms.discount") }}
            </td>
            <td class="pull-right">
              - {{ order.discount }} ({{ order.couponCode }})
            </td>
          </tr>
          <tr class="total">
            <td colspan="4">
              {{ $t("forms.total") }}
            </td>
            <td class="pull-right">
              {{ order.totalPrice }} {{ order.currency }}
            </td>
          </tr>
        </table>
      </fieldset>
      <fieldset class="mobile-full">
        <h3>{{ $t("forms.clientRemarks") }}</h3>
        <b-card>
          <b-row>
            <b-col>
              <b-form-group
                id="input-group-givenName"
                :label="$t('forms.givenName')"
                label-for="givenName"
                :class="{ error: v$.order.client.givenName.$errors.length }"
              >
                <b-form-input v-model="order.client.givenName" id="givenName"/>
                <div
                  class="input-errors"
                  v-for="error of v$.order.client.givenName.$errors"
                  :key="error.$uid"
                >
                  <small class="text-danger">{{ error.$message }}</small>
                </div>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-familyName"
                :label="$t('forms.familyName')"
                label-for="familyName"
                :class="{ error: v$.order.client.familyName.$errors.length }"
              >
                <b-form-input v-model="order.client.familyName" id="familyName"/>
                <div
                  class="input-errors"
                  v-for="error of v$.order.client.familyName.$errors"
                  :key="error.$uid"
                >
                  <small class="text-danger">{{ error.$message }}</small>
                </div>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-email"
                :label="$t('forms.email')"
                label-for="email"
                :class="{ error: v$.order.client.email.$errors.length }"
              >
                <b-form-input v-model="order.client.email" id="email"/>
                <div
                  class="input-errors"
                  v-for="error of v$.order.client.email.$errors"
                  :key="error.$uid"
                >
                  <small class="text-danger">{{ error.$message }}</small>
                </div>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-telephone"
                :label="$t('forms.telephone')"
                label-for="telephone"
                :class="{ error: v$.order.client.telephone.$errors.length }"
              >
                <b-form-input v-model="order.client.telephone" id="telephone"/>
                <div
                  class="input-errors"
                  v-for="error of v$.order.client.telephone.$errors"
                  :key="error.$uid"
                >
                  <small class="text-danger">{{ error.$message }}</small>
                </div>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-telephone"
                :label="$t('forms.aviationCompany')"
                label-for="aviationCompany"
                class=""
              >
                <b-form-input
                  v-model="order.client.airline"
                  id="aviationCompany"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="input-group-nationality"
                :label="$t('forms.nationality')"
                label-for="nationality"
                :class="{ error: v$.order.client.nationality.$errors.length }"
              >
                <b-form-input
                  v-model="order.client.nationality"
                  id="nationality"
                />
                <div
                  class="input-errors"
                  v-for="error of v$.order.client.nationality.$errors"
                  :key="error.$uid"
                >
                  <small class="text-danger">{{ error.$message }}</small>
                </div>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-aviationId"
                :label="$t('forms.aviationId')"
                label-for="aviationId"
                class=""
              >
                <document-upload @fileUploaded="setAviationId" accept="*" :simple="true"/>
                <a v-if="aviationIdUrl" :href="aviationIdUrl" target="_blank">View file</a>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </fieldset>
      <fieldset>
        <h3>{{ $t("forms.flightInformation") }}</h3>
        <b-card>
          <b-row>
            <b-col>
              <b-form-group
                id="input-group-flightNumberArrival"
                :label="$t('forms.flightNumberArrival')"
                label-for="flightNumberArrival"
                class=""
              >
                <b-form-input
                  v-model="order.flightNumberArrival"
                  id="flightNumberArrival"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-flightNumberDeparture"
                :label="$t('forms.flightNumberDeparture')"
                label-for="flightNumberDeparture"
                class=""
              >
                <b-form-input
                  v-model="order.flightNumberDeparture"
                  id="flightNumberDeparture"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-description"
                :label="$t('forms.clientRemarks')"
                label-for="description"
                class=""
              >
                <b-form-input v-model="order.description" id="description"/>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </fieldset>
      <fieldset class="mobile-full guests-add">
        <h3>{{ $t("forms.guestInformation") }}</h3>
        <b-card>
          <b-row
            v-for="(item, index) in order.guests"
            :key="index"
            class="order-user"
          >
            <b-col>
              <b-form-group
                id="input-group-guestFirstName"
                :label="$t('forms.givenName')"
                label-for="guestFirstName"
                class=""
              >
                <b-form-input v-model="item.givenName" id="guestFirstName"/>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-guestFamilyName"
                :label="$t('forms.familyName')"
                label-for="guestFamilyName"
                class=""
              >
                <b-form-input v-model="item.familyName" id="guestFamilyName"/>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-guestEmail"
                :label="$t('forms.email')"
                label-for="guestEmail"
                class=""
              >
                <b-form-input v-model="item.email" id="guestEmail"/>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-guestNationality"
                :label="$t('forms.nationality')"
                label-for="guestNationality"
                class=""
              >
                <b-form-input
                  v-model="item.nationality"
                  id="guestNationality"
                />
              </b-form-group>
            </b-col>
            <b-col v-if="'ageOfChild' in item">
              <b-form-group
                id="input-group-ageOfChild"
                :label="$t('forms.ageOfChild')"
                label-for="ageOfChild"
                class=""
              >
                <b-form-input v-model="item.ageOfChild" id="ageOfChild"/>
              </b-form-group>
            </b-col>
            <b-col v-if="'childBirthday' in item">
              <b-form-group
                id="input-group-childBirthday"
                :label="$t('forms.childBirthday')"
                label-for="childBirthday"
                class=""
              >
                <datepicker
                  id="childBirthday"
                  v-model="item.childBirthday"
                  class="form-input"
                  inputFormat="dd-MM-yyyy"
                />
              </b-form-group>
            </b-col>
            <delete-btn @pressDelete="order.guests.splice(index, 1)"/>
          </b-row>
          <b-button
            variant="gray"
            @click="addGuest()"
          >
            <bootstrap-icon icon="plus"/>
            {{ $t('messages.adultAdd') }}
          </b-button>
          <b-button
            variant="gray"
            @click="addGuest('child')"
          >
            <bootstrap-icon icon="plus"/>
            {{ $t('messages.childAdd') }}
          </b-button>

        </b-card>
      </fieldset>
      <fieldset class="mobile-full guests-add">
        <h3>Emails</h3>
        <b-card>
          <b-row class="order-user">
            <b-col>
              <b-form-group
                id="input-h-email-template"
                label="Email template"
                label-for="h-email-template"
                class=""
              >
                <v-select
                  id="h-email-template"
                  v-model="template"
                  class="col-3"
                  :options="['Booking received', 'Partner info', 'Booking confirmed']"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-button
            variant="gray"
            @click="resendEmail()"
          >
            <bootstrap-icon icon="envelope"/>
            Resend email
          </b-button>
        </b-card>
      </fieldset>
      <fieldset class="mobile-full guests-add">
        <h3>Documents</h3>
        <b-card>
          <b-button
            variant="gray"
            @click="viewInvoice()"
          >
            <bootstrap-icon icon="eye"/>
            View invoice
          </b-button>
          <b-button
            variant="gray"
            @click="viewVoucher()"
          >
            <bootstrap-icon icon="eye"/>
            View voucher
          </b-button>
        </b-card>
      </fieldset>
    </b-card>
  </b-form>
</template>

<script>
import {
  BRow,
  BForm,
  BCol,
  BFormGroup,
  BFormInput,
  BButton
} from "bootstrap-vue-3";
import Datepicker from "vue3-datepicker";
import vSelect from "vue-select";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import DocumentUpload from "@/components/DocumentUpload.vue";
import {useToast} from "vue-toastification";
import Toast from '@/components/Toast.vue';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    Datepicker,
    DeleteBtn,
    DocumentUpload
  },
  props: {
    orderObj: {
      type: Object, default() {
      }
    },
    action: {type: String, default: null},
    type: {type: String, default: 'update'},
  },
  data() {
    return {
      order: this.orderObj,
      title: "",
      statuses: [],
      financialStatuses: [],
      resorts: [],
      rooms: [],
      mealplans: [],
      transfers: [],
      template: "",
      aviationIdUrl: null
    };
  },
  mixins: [ListUtils, ResourceUtils],
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      order: {
        statusSelect: {required},
        resortSelect: {required},
        mealPlanSelect: {required},
        fromDate: {required},
        toDate: {required},
        roomSelect: {required},
        adults: {required},
        children: {required},
        infants: {required},
        transferSelect: {required},
        client: {
          givenName: {required},
          familyName: {required},
          email: {required},
          telephone: {required},
          nationality: {required}
        },
      },
    };
  },
  created() {
    this.getEnumList(["order_status", "resort_transfer_type", "order_payment_status"], this.statuses, this.transfers, this.financialStatuses);
    this.getResortList(this.resorts);
    if (this.order.client.fileUrl) {
      this.aviationIdUrl = this.order.client.fileUrl
    }
    if (this.type === 'update') {
      this.loadRooms();
      this.loadMealPlans();
    }
    this.order.guests.map(function (item) {
      if ('childBirthday' in item) {
        item.childBirthday = new Date(item.childBirthday);
      }
      return item
    });
  },
  watch: {
    action() {
      this.onSubmit();
    },
    resort() {
      this.order.roomSelect = null;
      this.loadRooms();
    },
    room() {
      this.order.mealPlanSelect = null
      this.loadMealPlans();
    },
    orderObj() {
      this.order.price = this.orderObj.price;
      this.order.paymentProcessingFee = this.orderObj.paymentProcessingFee;
      this.order.roomPrice = this.orderObj.roomPrice;
    }
  },
  computed: {
    resort() {
      return this.order.resortSelect;
    },
    room() {
      return this.order.roomSelect;
    },
    user() {
      return this.order.user;
    },
  },
  methods: {
    setAviationId(data) {
      this.order.client.file = data['@id'];
      this.aviationIdUrl = data.url;
      if ('id' in this.order) {
        const body = {
          client: {
            file: this.order.client.file
          }
        }
        this.updateOrder(this.order.id, body);
      }

    },
    loadRooms() {
      this.rooms = [];
      const url = "/resorts/" + this.order.resortSelect.numberId + "/rooms";
      this.$Resorts.getResourceByUrl({url}, 'room_list').then((response) => {
        response.data["hydra:member"].forEach((element) => {
          const room = {
            label: element.name,
            id: element["@id"],
            numberId: element.id,
          };
          this.rooms.push(room);
        });
      });
    },
    loadMealPlans() {
      this.mealplans = []
      if (this.order.roomSelect) {
        const url = "/room_variants/" + this.order.roomSelect.numberId + "/meal_plans";
        this.$MealPlans.getResourceByUrl({url}, 'meal_plan_list').then((response) => {
          response.data["hydra:member"].forEach((element) => {
            const mealplan = {
              label: this.$helper.getEnumTranslation(
                "meal_plan_type",
                element.type
              ),
              id: element["@id"],
              numberId: element.id,
            };
            this.mealplans.push(mealplan);
          });
        });
      }
    },
    viewInvoice() {
      this.$Pdfs.getResourceByUrl({url: `/pdfs/invoice_${this.order.id}`}).then(response => {
        if (response.status === 200) {
          const link = document.createElement('a')
          link.href = response.data.url;
          link.target = '_blank'
          link.click()
        }
      }).catch(() => {
        this.$helper.showToast(useToast(), Toast, 'An error occurred', 'Invoice was not generated yet', 'danger')
      })
    },
    viewVoucher() {
      this.$Pdfs.getResourceByUrl({url: `/pdfs/voucher_${this.order.id}`}).then(response => {
        if (response.status === 200) {
          const link = document.createElement('a')
          link.href = response.data.url;
          link.target = '_blank'
          link.click()
        }
      }).catch(() => {
        this.$helper.showToast(useToast(), Toast, 'An error occurred', 'Voucher was not generated yet', 'danger')
      })
    },
    addGuest(type = 'adult') {
      let obj = {
        givenName: "",
        familyName: "",
        email: "",
        nationality: "",
      }
      if (type === "child") {
        obj.ageOfChild = 0;
        obj.childBirthday = new Date();
      }
      this.order.guests.push(obj);
    },
    resendEmail() {
      let template
      if (this.template === 'Booking confirmed' && this.order.paymentMethod === 'CARD') {
        template = 'BOOKING_CONFIRMED_CARD'
      }
      if (this.template === 'Booking confirmed' && this.order.paymentMethod === 'BANK_TRANSFER') {
        template = 'BOOKING_CONFIRMED_BANK_TRANSFER'
      }
      if (this.template === 'Booking received' && this.order.paymentMethod === 'CARD') {
        template = 'BOOKING_NEW_CARD'
      }
      if (this.template === 'Booking received' && this.order.paymentMethod === 'BANK_TRANSFER') {
        template = 'BOOKING_NEW_BANK_TRANSFER'
      }
      if (this.template === 'Partner info' && this.order.paymentMethod === 'CARD') {
        template = 'PARTNER_INFO_CARD'
      }
      if (this.template === 'Partner info' && this.order.paymentMethod === 'BANK_TRANSFER') {
        template = 'PARTNER_INFO_BANK_TRANSFER'
      }
      this.$Emails.createResourceByUrl({
        url: '/resend_emails',
        body: {resourceIri: this.order['@id'], templateName: template}
      }).then((response) => {
        if (response.status === 201) {
          this.$helper.showToast(useToast(), Toast, 'Email has been successfully sent', '', 'success')
        }
      })
        .catch((error) => {
          if (error.response) {
            this.$helper.showToast(
              useToast(),
              Toast,
              error.response.data["hydra:title"],
              error.response.data["hydra:description"],
              "danger"
            );
          }
        });
    },
    createUser(body) {
      delete body.id;
      return this.create(this.$Users, body, this.$t("messages.customerCreated"), null, function (response) {
        body['@id'] = response.data["@id"]
      }, this.error)
    },
    updateUser(id, body) {
      return this.update(this.$Users, id, body, this.$t("messages.customerUpdated"), null, null, this.error);
    },
    updateOrder(id, body) {
      return this.update(this.$Orders, id, body, this.$t("messages.orderUpdated"), null, this.success, this.error);
    },
    createOrder(body) {
      return this.create(this.$Orders, body, this.$t("messages.orderCreated"), null, this.redirect, this.error)
    },
    async onSubmit() {
      const isValid = await this.v$.$validate();
      let guests = this.order.guests.map(function (item) {
        if ('ageOfChild' in item) {
          item.ageOfChild = parseInt(item.ageOfChild, 10);
        }
        if ('childBirthday' in item) {
          item.childBirthday = new Date(item.childBirthday);
        }
        return item
      });
      if (isValid) {
        const fromDate = new Date(
          this.order.fromDate.getTime() + Math.abs(this.order.fromDate.getTimezoneOffset() * 60000)
        );
        const toDate = new Date(
          this.order.toDate.getTime() + Math.abs(this.order.toDate.getTimezoneOffset() * 60000)
        );
        const body = {
          fromDate: fromDate,
          toDate: toDate,
          adults: parseInt(this.order.adults),
          children: parseInt(this.order.children),
          infants: parseInt(this.order.infants),
          flightNumberArrival: this.order.flightNumberArrival,
          flightNumberDeparture: this.order.flightNumberDeparture,
          description: this.order.description,
          client: this.order.client,
          guests: guests,
          mealPlan: this.order.mealPlanSelect.id,
          confirmationNumber: this.order.confirmationNumber,
          transfer: this.order.transferSelect.id
        };
        if (this.action === "add") {
          body.roomCount = 1;
          this.createOrder(body)
        }
        if (this.action === "update") {
          const id = this.$route.params.id;
          body.status = this.order.statusSelect.id
          if (this.order.paymentMethod === 'BANK_TRANSFER') {
            body.financialStatus = this.order.financialStatusSelect.id
          }

          this.updateOrder(id, body);
        }
      } else {
        this.$emit("clearAction");
      }
    },
    redirect() {
      this.$router.push(`/orders`);
    },
    success() {
      this.$emit("clearAction");
    },
    error() {
      this.$emit("clearAction");
    }
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
